import React from 'react';
import { Provider } from 'mobx-react';
import SnackBarStore from './ui/stores/SnackBarStore';
import LoaderStore from './ui/stores/LoaderStore';
import QsiseService from './services/QsiseService';
import QsiseExecute from './ui/qsise/crud/QsiseExecute';


function QsiseExecuteWrapper({ endpoint, qSiseID, options: { credentials, variables, qSiseIndex }, theme, externalSource, mode, externalData, customComponents }) {
  console.log('Qsise Wrapper rendered!');
  const snackBarStore = new SnackBarStore();
  const loaderStore = new LoaderStore();
  const endpointApi = `${endpoint}`;
  const endpointToken = `${endpoint}/Token`;

  const qSiseService = new QsiseService(
    `${endpointApi}/Qsise/all`,
    `${endpointApi}/Qsise/Add`,
    `${endpointApi}/Qsise/Update`,
    `${endpointApi}/Qsise/Delete`,
    `${endpointApi}/Qsise/ExecuteQuery`,
    `${endpointApi}/Qsise/Get`
  );

  const services = {
    qSiseService
  };

  const settings = {
    apiEndpoint: endpointApi,
    qSiseID: qSiseID,
    variables: variables,
    qSiseIndex: qSiseIndex,
    theme,
    externalSource,
    mode,
    externalData,
    customComponents
  };

  const stores = { snackBarStore, loaderStore };

  const newQsiseExecute = (
    <Provider {...settings} {...stores} {...services}>
      <QsiseExecute />
    </Provider>
  );
  
  return newQsiseExecute;
}

export default QsiseExecuteWrapper;