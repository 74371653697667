/* eslint-disable */
import React, { Component, createElement } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import reactToWebComponent from "react-to-webcomponent"
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Handlebars from 'handlebars';
import HandlebarsIntl from 'handlebars-intl';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import orange from '@material-ui/core/colors/orange';
//import '../../styles/preview.css';
import PreviewHelpers from './PreviewHelpers';
import PreviewListeners from './PreviewListeners';
import { Typography } from '@material-ui/core';
import '../../wc/Range';
import '../../wc/Carousel';
import '../../wc/Tabs';

const styleMaterial = () => ({
  label: {
    color: 'white'
  },
  root: {
    backgroundColor: orange[500]
  }
});

const PreviewStyles = styled.div`
.preview *,
.preview :after,
.preview :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.preview {
  font-size: 14px;
  line-height: 1.42857143;
}
.preview .container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 3px solid #d3d3d3;
}

.preview .section {
  margin: 10px;
  padding: 10px;
}

.preview .cols {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.preview .rows {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.preview .primary {
  background-color: rgb(226, 233, 249) !important;
}

.preview .secondary {
  background-color: rgb(239, 239, 239) !important;
}

/*
.preview .header .description {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.preview .body {
  background-color: #d3d3d3;
  margin: 10px;
  padding: 10px;
}


.preview .content {
  display: flex;
  flex-direction: column;
  background-color: #d3d3d3;
  margin: 10px;
  padding: 10px;
}

.preview .content .table {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #999595;
  padding: 3px;
} */

.info {
  font-weight: bold;
}

/* Table Styles */

.preview .sise-table {
  /* flex: auto 1; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: stretch; */
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  font-family: Roboto, sans-serif;
  overflow: auto;
  background-color: #fff;
  border: none;
  border-spacing: 0;
}

.preview .sise-table tr {
  text-align: center;
  /* display: inline-flex; */
  /* flex: 1 0 auto; */
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.preview .sise-table tr:last-child {
  border-bottom: none;
}

.preview .sise-table th,
.preview .sise-table td {
  /* flex: 1; */
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  /* display: block; */
}

.preview .sise-table th {
  padding: 5px;
  line-height: 30px;
  position: relative;
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  background-color: #e6e6e6;
  color: #888;
  max-width: 100% !important;
  font-weight: 400;
}

.preview .sise-table th:last-child,
.preview .sise-table td:last-child {
  border-right: none;
}

.preview .sise-table td:last-child {
  border-bottom: none;
}

.preview .sise-table td {
  padding: 7px 5px;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
}

.preview .row {
  margin: 0 !important;
}

/* Table styles end */

/* Panel styles */

.preview .panel {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.preview .panel .header {
  margin-bottom: -1px;
  border-radius: 0.28571429rem 0.28571429rem 0 0;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
  margin-left: -1px;
  margin-right: -1px;
  font-size: 1.14285714em;
  font-weight: 700;
  position: relative;
  min-height: 1em;
  background-color: #f8f8f9;
  padding: 1em 1.5em;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.87);
  transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease,
    box-shadow 0.1s ease;
}

.preview .panel .body {
  top: 0;
  bottom: 0;
  border-radius: 0;
  margin: 0 -1px;
  box-shadow: none;
  border: 1px solid #d4d4d5;
  position: relative;
  background-color: #fff;
}

/* Panel styles end */

/* List styles */

.preview .list {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.preview .list h1,
.preview .list h2,
.preview .list h3,
.preview .list h4,
.preview .list h5,
.preview .list h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.preview .list p {
  margin-top: 0;
  margin-bottom: 0.25rem;
}

.preview .list small {
  font-size: 80%;
  font-weight: 400;
  color: #6c757d;
}

.preview .list .list-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.preview .list .list-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

/* List styles end */

/* Button */

.preview .sise-btn {
  margin: 8px;
  padding: 6px 16px;
  font-size: 0.875rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  background-color: #e0e0e0;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  outline: none;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.preview .sise-btn:hover {
  background-color: #d3d3d3 !important;
}

.preview .btn.circle {
  width: 40px;
  height: 40px;
  padding: 0;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  min-width: 0;
  min-height: 36px;
}

/* Button end */

/* UifDataTable */

.preview .uif-col-xs-1,
.preview .col-sm-1,
.preview .uif-col-1,
.preview .uif-col-lg-1,
.preview .uif-col-xs-2,
.preview .col-sm-2,
.preview .uif-col-2,
.preview .uif-col-lg-2,
.preview .uif-col-xs-3,
.preview .col-sm-3,
.preview .uif-col-3,
.preview .uif-col-lg-3,
.preview .uif-col-xs-4,
.preview .col-sm-4,
.preview .uif-col-4,
.preview .uif-col-lg-4,
.preview .uif-col-xs-5,
.preview .col-sm-5,
.preview .uif-col-5,
.preview .uif-col-lg-5,
.preview .uif-col-xs-6,
.preview .col-sm-6,
.preview .uif-col-6,
.preview .uif-col-lg-6,
.preview .uif-col-xs-7,
.preview .col-sm-7,
.preview .uif-col-7,
.preview .uif-col-lg-7,
.preview .uif-col-xs-8,
.preview .col-sm-8,
.preview .uif-col-8,
.preview .uif-col-lg-8,
.preview .uif-col-xs-9,
.preview .col-sm-9,
.preview .uif-col-9,
.preview .uif-col-lg-9,
.preview .uif-col-xs-10,
.preview .col-sm-10,
.preview .uif-col-10,
.preview .uif-col-lg-10,
.preview .uif-col-xs-11,
.preview .col-sm-11,
.preview .uif-col-11,
.preview .uif-col-lg-11,
.preview .uif-col-xs-12,
.preview .col-sm-12,
.preview .uif-col-12,
.preview .uif-col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.preview .uif-col-xs-1,
.preview .uif-col-xs-2,
.preview .uif-col-xs-3,
.preview .uif-col-xs-4,
.preview .uif-col-xs-5,
.preview .uif-col-xs-6,
.preview .uif-col-xs-7,
.preview .uif-col-xs-8,
.preview .uif-col-xs-9,
.preview .uif-col-xs-10,
.preview .uif-col-xs-11,
.preview .uif-col-xs-12 {
  float: left;
}
.preview .uif-col-xs-12 {
  width: 100%;
}
.preview .uif-col-xs-11 {
  width: 91.66666667%;
}
.preview .uif-col-xs-10 {
  width: 83.33333333%;
}
.preview .uif-col-xs-9 {
  width: 75%;
}
.preview .uif-col-xs-8 {
  width: 66.66666667%;
}
.preview .uif-col-xs-7 {
  width: 58.33333333%;
}
.preview .uif-col-xs-6 {
  width: 50%;
}
.preview .uif-col-xs-5 {
  width: 41.66666667%;
}
.preview .uif-col-xs-4 {
  width: 33.33333333%;
}
.preview .uif-col-xs-3 {
  width: 25%;
}
.preview .uif-col-xs-2 {
  width: 16.66666667%;
}
.preview .uif-col-xs-1 {
  width: 8.33333333%;
}
.preview .uif-col-xs-pull-12 {
  right: 100%;
}
.preview .uif-col-xs-pull-11 {
  right: 91.66666667%;
}
.preview .uif-col-xs-pull-10 {
  right: 83.33333333%;
}
.preview .uif-col-xs-pull-9 {
  right: 75%;
}
.preview .uif-col-xs-pull-8 {
  right: 66.66666667%;
}
.preview .uif-col-xs-pull-7 {
  right: 58.33333333%;
}
.preview .uif-col-xs-pull-6 {
  right: 50%;
}
.preview .uif-col-xs-pull-5 {
  right: 41.66666667%;
}
.preview .uif-col-xs-pull-4 {
  right: 33.33333333%;
}
.preview .uif-col-xs-pull-3 {
  right: 25%;
}
.preview .uif-col-xs-pull-2 {
  right: 16.66666667%;
}
.preview .uif-col-xs-pull-1 {
  right: 8.33333333%;
}
.preview .uif-col-xs-pull-0 {
  right: auto;
}
.preview .uif-col-xs-push-12 {
  left: 100%;
}
.preview .uif-col-xs-push-11 {
  left: 91.66666667%;
}
.preview .uif-col-xs-push-10 {
  left: 83.33333333%;
}
.preview .uif-col-xs-push-9 {
  left: 75%;
}
.preview .uif-col-xs-push-8 {
  left: 66.66666667%;
}
.preview .uif-col-xs-push-7 {
  left: 58.33333333%;
}
.preview .uif-col-xs-push-6 {
  left: 50%;
}
.preview .uif-col-xs-push-5 {
  left: 41.66666667%;
}
.preview .uif-col-xs-push-4 {
  left: 33.33333333%;
}
.preview .uif-col-xs-push-3 {
  left: 25%;
}
.preview .uif-col-xs-push-2 {
  left: 16.66666667%;
}
.preview .uif-col-xs-push-1 {
  left: 8.33333333%;
}
.preview .uif-col-xs-push-0 {
  left: auto;
}
.preview .uif-col-xs-offset-12 {
  margin-left: 100%;
}
.preview .uif-col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.preview .uif-col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.preview .uif-col-xs-offset-9 {
  margin-left: 75%;
}
.preview .uif-col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.preview .uif-col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.preview .uif-col-xs-offset-6 {
  margin-left: 50%;
}
.preview .uif-col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.preview .uif-col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.preview .uif-col-xs-offset-3 {
  margin-left: 25%;
}
.preview .uif-col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.preview .uif-col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.preview .uif-col-xs-offset-0 {
  margin-left: 0;
}
@media (min-width: 768px) {
  .preview .col-sm-1,
  .preview .col-sm-2,
  .preview .col-sm-3,
  .preview .col-sm-4,
  .preview .col-sm-5,
  .preview .col-sm-6,
  .preview .col-sm-7,
  .preview .col-sm-8,
  .preview .col-sm-9,
  .preview .col-sm-10,
  .preview .col-sm-11,
  .preview .col-sm-12 {
    float: left;
  }
  .preview .col-sm-12 {
    width: 100%;
  }
  .preview .col-sm-11 {
    width: 91.66666667%;
  }
  .preview .col-sm-10 {
    width: 83.33333333%;
  }
  .preview .col-sm-9 {
    width: 75%;
  }
  .preview .col-sm-8 {
    width: 66.66666667%;
  }
  .preview .col-sm-7 {
    width: 58.33333333%;
  }
  .preview .col-sm-6 {
    width: 50%;
  }
  .preview .col-sm-5 {
    width: 41.66666667%;
  }
  .preview .col-sm-4 {
    width: 33.33333333%;
  }
  .preview .col-sm-3 {
    width: 25%;
  }
  .preview .col-sm-2 {
    width: 16.66666667%;
  }
  .preview .col-sm-1 {
    width: 8.33333333%;
  }
  .preview .col-sm-pull-12 {
    right: 100%;
  }
  .preview .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .preview .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .preview .col-sm-pull-9 {
    right: 75%;
  }
  .preview .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .preview .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .preview .col-sm-pull-6 {
    right: 50%;
  }
  .preview .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .preview .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .preview .col-sm-pull-3 {
    right: 25%;
  }
  .preview .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .preview .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .preview .col-sm-pull-0 {
    right: auto;
  }
  .preview .col-sm-push-12 {
    left: 100%;
  }
  .preview .col-sm-push-11 {
    left: 91.66666667%;
  }
  .preview .col-sm-push-10 {
    left: 83.33333333%;
  }
  .preview .col-sm-push-9 {
    left: 75%;
  }
  .preview .col-sm-push-8 {
    left: 66.66666667%;
  }
  .preview .col-sm-push-7 {
    left: 58.33333333%;
  }
  .preview .col-sm-push-6 {
    left: 50%;
  }
  .preview .col-sm-push-5 {
    left: 41.66666667%;
  }
  .preview .col-sm-push-4 {
    left: 33.33333333%;
  }
  .preview .col-sm-push-3 {
    left: 25%;
  }
  .preview .col-sm-push-2 {
    left: 16.66666667%;
  }
  .preview .col-sm-push-1 {
    left: 8.33333333%;
  }
  .preview .col-sm-push-0 {
    left: auto;
  }
  .preview .col-sm-offset-12 {
    margin-left: 100%;
  }
  .preview .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .preview .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .preview .col-sm-offset-9 {
    margin-left: 75%;
  }
  .preview .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .preview .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .preview .col-sm-offset-6 {
    margin-left: 50%;
  }
  .preview .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .preview .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .preview .col-sm-offset-3 {
    margin-left: 25%;
  }
  .preview .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .preview .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .preview .col-sm-offset-0 {
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .preview .uif-col-1,
  .preview .uif-col-2,
  .preview .uif-col-3,
  .preview .uif-col-4,
  .preview .uif-col-5,
  .preview .uif-col-6,
  .preview .uif-col-7,
  .preview .uif-col-8,
  .preview .uif-col-9,
  .preview .uif-col-10,
  .preview .uif-col-11,
  .preview .uif-col-12 {
    float: left;
  }
  .preview .uif-col-12 {
    width: 100%;
  }
  .preview .uif-col-11 {
    width: 91.66666667%;
  }
  .preview .uif-col-10 {
    width: 83.33333333%;
  }
  .preview .uif-col-9 {
    width: 75%;
  }
  .preview .uif-col-8 {
    width: 66.66666667%;
  }
  .preview .uif-col-7 {
    width: 58.33333333%;
  }
  .preview .uif-col-6 {
    width: 50%;
  }
  .preview .uif-col-5 {
    width: 41.66666667%;
  }
  .preview .uif-col-4 {
    width: 33.33333333%;
  }
  .preview .uif-col-3 {
    width: 25%;
  }
  .preview .uif-col-2 {
    width: 16.66666667%;
  }
  .preview .uif-col-1 {
    width: 8.33333333%;
  }
  .preview .uif-col-pull-12 {
    right: 100%;
  }
  .preview .uif-col-pull-11 {
    right: 91.66666667%;
  }
  .preview .uif-col-pull-10 {
    right: 83.33333333%;
  }
  .preview .uif-col-pull-9 {
    right: 75%;
  }
  .preview .uif-col-pull-8 {
    right: 66.66666667%;
  }
  .preview .uif-col-pull-7 {
    right: 58.33333333%;
  }
  .preview .uif-col-pull-6 {
    right: 50%;
  }
  .preview .uif-col-pull-5 {
    right: 41.66666667%;
  }
  .preview .uif-col-pull-4 {
    right: 33.33333333%;
  }
  .preview .uif-col-pull-3 {
    right: 25%;
  }
  .preview .uif-col-pull-2 {
    right: 16.66666667%;
  }
  .preview .uif-col-pull-1 {
    right: 8.33333333%;
  }
  .preview .uif-col-pull-0 {
    right: auto;
  }
  .preview .uif-col-push-12 {
    left: 100%;
  }
  .preview .uif-col-push-11 {
    left: 91.66666667%;
  }
  .preview .uif-col-push-10 {
    left: 83.33333333%;
  }
  .preview .uif-col-push-9 {
    left: 75%;
  }
  .preview .uif-col-push-8 {
    left: 66.66666667%;
  }
  .preview .uif-col-push-7 {
    left: 58.33333333%;
  }
  .preview .uif-col-push-6 {
    left: 50%;
  }
  .preview .uif-col-push-5 {
    left: 41.66666667%;
  }
  .preview .uif-col-push-4 {
    left: 33.33333333%;
  }
  .preview .uif-col-push-3 {
    left: 25%;
  }
  .preview .uif-col-push-2 {
    left: 16.66666667%;
  }
  .preview .uif-col-push-1 {
    left: 8.33333333%;
  }
  .preview .uif-col-push-0 {
    left: auto;
  }
  .preview .uif-col-offset-12 {
    margin-left: 100%;
  }
  .preview .uif-col-offset-11 {
    margin-left: 91.66666667%;
  }
  .preview .uif-col-offset-10 {
    margin-left: 83.33333333%;
  }
  .preview .uif-col-offset-9 {
    margin-left: 75%;
  }
  .preview .uif-col-offset-8 {
    margin-left: 66.66666667%;
  }
  .preview .uif-col-offset-7 {
    margin-left: 58.33333333%;
  }
  .preview .uif-col-offset-6 {
    margin-left: 50%;
  }
  .preview .uif-col-offset-5 {
    margin-left: 41.66666667%;
  }
  .preview .uif-col-offset-4 {
    margin-left: 33.33333333%;
  }
  .preview .uif-col-offset-3 {
    margin-left: 25%;
  }
  .preview .uif-col-offset-2 {
    margin-left: 16.66666667%;
  }
  .preview .uif-col-offset-1 {
    margin-left: 8.33333333%;
  }
  .preview .uif-col-offset-0 {
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .preview .uif-col-lg-1,
  .preview .uif-col-lg-2,
  .preview .uif-col-lg-3,
  .preview .uif-col-lg-4,
  .preview .uif-col-lg-5,
  .preview .uif-col-lg-6,
  .preview .uif-col-lg-7,
  .preview .uif-col-lg-8,
  .preview .uif-col-lg-9,
  .preview .uif-col-lg-10,
  .preview .uif-col-lg-11,
  .preview .uif-col-lg-12 {
    float: left;
  }
  .preview .uif-col-lg-12 {
    width: 100%;
  }
  .preview .uif-col-lg-11 {
    width: 91.66666667%;
  }
  .preview .uif-col-lg-10 {
    width: 83.33333333%;
  }
  .preview .uif-col-lg-9 {
    width: 75%;
  }
  .preview .uif-col-lg-8 {
    width: 66.66666667%;
  }
  .preview .uif-col-lg-7 {
    width: 58.33333333%;
  }
  .preview .uif-col-lg-6 {
    width: 50%;
  }
  .preview .uif-col-lg-5 {
    width: 41.66666667%;
  }
  .preview .uif-col-lg-4 {
    width: 33.33333333%;
  }
  .preview .uif-col-lg-3 {
    width: 25%;
  }
  .preview .uif-col-lg-2 {
    width: 16.66666667%;
  }
  .preview .uif-col-lg-1 {
    width: 8.33333333%;
  }
  .preview .uif-col-lg-pull-12 {
    right: 100%;
  }
  .preview .uif-col-lg-pull-11 {
    right: 91.66666667%;
  }
  .preview .uif-col-lg-pull-10 {
    right: 83.33333333%;
  }
  .preview .uif-col-lg-pull-9 {
    right: 75%;
  }
  .preview .uif-col-lg-pull-8 {
    right: 66.66666667%;
  }
  .preview .uif-col-lg-pull-7 {
    right: 58.33333333%;
  }
  .preview .uif-col-lg-pull-6 {
    right: 50%;
  }
  .preview .uif-col-lg-pull-5 {
    right: 41.66666667%;
  }
  .preview .uif-col-lg-pull-4 {
    right: 33.33333333%;
  }
  .preview .uif-col-lg-pull-3 {
    right: 25%;
  }
  .preview .uif-col-lg-pull-2 {
    right: 16.66666667%;
  }
  .preview .uif-col-lg-pull-1 {
    right: 8.33333333%;
  }
  .preview .uif-col-lg-pull-0 {
    right: auto;
  }
  .preview .uif-col-lg-push-12 {
    left: 100%;
  }
  .preview .uif-col-lg-push-11 {
    left: 91.66666667%;
  }
  .preview .uif-col-lg-push-10 {
    left: 83.33333333%;
  }
  .preview .uif-col-lg-push-9 {
    left: 75%;
  }
  .preview .uif-col-lg-push-8 {
    left: 66.66666667%;
  }
  .preview .uif-col-lg-push-7 {
    left: 58.33333333%;
  }
  .preview .uif-col-lg-push-6 {
    left: 50%;
  }
  .preview .uif-col-lg-push-5 {
    left: 41.66666667%;
  }
  .preview .uif-col-lg-push-4 {
    left: 33.33333333%;
  }
  .preview .uif-col-lg-push-3 {
    left: 25%;
  }
  .preview .uif-col-lg-push-2 {
    left: 16.66666667%;
  }
  .preview .uif-col-lg-push-1 {
    left: 8.33333333%;
  }
  .preview .uif-col-lg-push-0 {
    left: auto;
  }
  .preview .uif-col-lg-offset-12 {
    margin-left: 100%;
  }
  .preview .uif-col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .preview .uif-col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .preview .uif-col-lg-offset-9 {
    margin-left: 75%;
  }
  .preview .uif-col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .preview .uif-col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .preview .uif-col-lg-offset-6 {
    margin-left: 50%;
  }
  .preview .uif-col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .preview .uif-col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .preview .uif-col-lg-offset-3 {
    margin-left: 25%;
  }
  .preview .uif-col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .preview .uif-col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .preview .uif-col-lg-offset-0 {
    margin-left: 0;
  }
}
.preview .uif-row {
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: 10px;
}
.preview .uif-row:before,
.preview .uif-row:after {
  display: table;
  content: ' ';
}
.preview .uif-row:after {
  clear: both;
}
.preview .row {
  margin-right: -15px;
  margin-left: -15px;
}
.preview .btn-group-vertical > .btn-group:after,
.preview .btn-group-vertical > .btn-group:before,
.preview .btn-toolbar:after,
.preview .btn-toolbar:before,
.preview .clearfix:after,
.preview .clearfix:before,
.preview .container-fluid:after,
.preview .container-fluid:before,
.preview .container:after,
.preview .container:before,
.preview .dl-horizontal dd:after,
.preview .dl-horizontal dd:before,
.preview .form-horizontal .form-group:after,
.preview .form-horizontal .form-group:before,
.preview .modal-footer:after,
.preview .modal-footer:before,
.preview .modal-header:after,
.preview .modal-header:before,
.preview .nav:after,
.preview .nav:before,
.preview .navbar-collapse:after,
.preview .navbar-collapse:before,
.preview .navbar-header:after,
.preview .navbar-header:before,
.preview .navbar:after,
.preview .navbar:before,
.preview .pager:after,
.preview .pager:before,
.preview .panel-body:after,
.preview .panel-body:before,
.preview .row:after,
.preview .row:before {
  display: table;
  content: ' ';
}
.preview .btn-group-vertical > .btn-group:after,
.preview .btn-toolbar:after,
.preview .clearfix:after,
.preview .container-fluid:after,
.preview .container:after,
.preview .dl-horizontal dd:after,
.preview .form-horizontal .form-group:after,
.preview .modal-footer:after,
.preview .modal-header:after,
.preview .nav:after,
.preview .navbar-collapse:after,
.preview .navbar-header:after,
.preview .navbar:after,
.preview .pager:after,
.preview .panel-body:after,
.preview .row:after {
  clear: both;
}
.preview .btn-group-vertical > .btn-group:after,
.preview .btn-group-vertical > .btn-group:before,
.preview .btn-toolbar:after,
.preview .btn-toolbar:before,
.preview .clearfix:after,
.preview .clearfix:before,
.preview .container-fluid:after,
.preview .container-fluid:before,
.preview .container:after,
.preview .container:before,
.preview .dl-horizontal dd:after,
.preview .dl-horizontal dd:before,
.preview .form-horizontal .form-group:after,
.preview .form-horizontal .form-group:before,
.preview .modal-footer:after,
.preview .modal-footer:before,
.preview .modal-header:after,
.preview .modal-header:before,
.preview .nav:after,
.preview .nav:before,
.preview .navbar-collapse:after,
.preview .navbar-collapse:before,
.preview .navbar-header:after,
.preview .navbar-header:before,
.preview .navbar:after,
.preview .navbar:before,
.preview .pager:after,
.preview .pager:before,
.preview .panel-body:after,
.preview .panel-body:before,
.preview .row:after,
.preview .row:before {
  display: table;
  content: ' ';
}
.preview .primary {
  color: #227af6;
}
.preview .success {
  color: #5cb85c;
}
.preview .warning {
  color: #f0ad4e;
}
.preview .danger {
  color: #e04032;
}
.preview .no-padding {
  padding: 0 !important;
}
.preview .no-margin {
  margin: 0 !important;
}
.preview h1,
.preview h2,
.preview h3,
.preview h4,
.preview h5,
.preview h6 {
  margin: 15px auto;
}
.preview h1 i,
.preview h2 i,
.preview h3 i,
.preview h4 i,
.preview h5 i,
.preview h6 i {
  margin-right: 0.4em;
}
.preview hr {
  border-top: 1px solid #bdbdbd;
}
.preview a {
  color: #337ab7;
  text-decoration: none;
  background-color: transparent;
}
.preview table {
  border-spacing: 0;
  border-collapse: collapse;
}
.preview .table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}
.preview pre code,
.preview table {
  background-color: transparent;
}

.preview caption,
th {
  text-align: left;
}

.preview button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}

.preview button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.preview .btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.preview .btn-default {
  color: #09427c;
  border-color: #d5d6db;
  background: #f0f1f5;
  background: linear-gradient(to bottom, #f0f1f5 0%, #e3e4e8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0f1f5', endColorstr='#e3e4e8',GradientType=0);
}
.preview .btn-default i,
.preview .btn-default .caret {
  color: #09427c;
}
.preview .panel-table {
  background: #f6f6f6;
}
.preview .panel-table .scrolling {
  overflow-x: auto;
}
.preview .panel-table .panel-heading {
  margin: 0;
  margin-bottom: 10px;
  overflow: hidden;
  padding: 10px 5px;
}
.preview .panel-table .panel-heading .panel-title {
  color: #09427c;
  font-size: 24px;
  font-weight: bold;
  border-bottom: 1px solid #09427c;
  margin-bottom: 10px;
  padding-bottom: 6px;
}
.preview .panel-table .panel-heading .panel-actions {
  float: right;
}
.preview .panel-table .panel-heading .panel-actions .btn {
  line-height: 14px;
  margin-left: 5px;
}
.preview .panel-table tfoot {
  display: table-header-group;
}
.preview .panel-table tfoot input {
  padding: 0 !important;
}
.preview .panel-table thead {
  color: #333;
  font-size: 14px;
  border: 1px solid #999;
  border-bottom: 3px solid #999;
}
.preview .panel-table thead th {
  text-align: center;
}
.preview .panel-table tbody tr {
  background: #f2f3f7;
  color: #333;
  font-size: 14px;
}
.preview .panel-table tbody tr td {
  border-top: 0px !important;
  border-bottom: 1px solid #fff;
}
.preview .panel-table .btn {
  color: #09427c;
  border-color: #ddd;
  background: linear-gradient(to bottom, #ececec, #d6d6d6);
}
.preview .panel-table .table-hover > tbody > tr:hover,
.preview .panel-table .table > tbody > tr.active > td,
.preview .panel-table .table > tbody > tr.active > th,
.preview .panel-table .table > tbody > tr > td.active,
.preview .panel-table .table > tbody > tr > th.active,
.preview .panel-table .table > tfoot > tr.active > td,
.preview .panel-table .table > tfoot > tr.active > th,
.preview .panel-table .table > tfoot > tr > td.active,
.preview .panel-table .table > tfoot > tr > th.active,
.preview .panel-table .table > thead > tr.active > td,
.preview .panel-table .table > thead > tr.active > th,
.preview .panel-table .table > thead > tr > td.active,
.preview .panel-table .table > thead > tr > th.active {
  background: #e0eefb;
}
.preview .panel-table td.justified-right {
  text-align: right;
}
.preview .panel-table .div-button-multiple {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
}
.preview .panel-table .div-button-single {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
}
.preview .panel-table .th-actions {
  width: 150px !important;
}
.preview .panel-table .row-selected {
  background: #e0eefb;
  font-weight: bolder;
}
.preview .panel-table .tfilter {
  margin-top: 10px;
}
.preview .panel-table .tfooter {
  padding-top: 8px;
  border: 0 solid;
  padding-bottom: 5px;
  border-bottom: 1px solid #e6e6e6;
  background: #e6edf7;
}
.preview .panel-table .btn-table {
  background: #fff;
  color: #09427c;
  border-radius: 50%;
  font-size: 14px;
  width: 32px;
  height: 32px;
}
.preview .panel-table .btn-table span {
  color: #09427c !important;
}
.preview .pagination {
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}
.preview .breadcrumb > li,
.preview .pagination {
  display: inline-block;
}
.preview .pager li,
.preview .pagination > li {
  display: inline;
}
.preview .pagination > li:first-child > a,
.preview .pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.preview .pagination > .disabled > a,
.preview .pagination > .disabled > a:focus,
.preview .pagination > .disabled > a:hover,
.preview .pagination > .disabled > span,
.preview .pagination > .disabled > span:focus,
.preview .pagination > .disabled > span:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}
.preview .pagination > li > a,
.preview .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
.preview .pagination > .active > a,
.preview .pagination > .active > a:focus,
.preview .pagination > .active > a:hover,
.preview .pagination > .active > span,
.preview .pagination > .active > span:focus,
.preview .pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7;
}
.preview .panel-table .pagination > li:first-child > a,
.preview .panel-table .pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.preview .panel-table .pagination > li:last-child > a,
.preview .panel-table .pagination > li:last-child > span {
  margin-left: 0;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.preview .panel-table .pagination {
  background: #fafafa;
  color: #999;
  font-size: 13px;
}
.preview .panel-table select,
.preview .panel-table input {
  border-radius: 0;
  font-size: 13px;
}
.preview .panel-table .dataTables_filter {
  text-align: left !important;
}
.preview .panel-table input[type='search'] {
  height: 30px;
  margin: 0px !important;
}
.preview .panel-table table.dataTable thead th {
  position: relative;
  background-image: none !important;
}
.preview .panel-table table.dataTable thead th.sorting:after,
.preview .panel-table table.dataTable thead th.sorting_asc:after,
.preview .panel-table table.dataTable thead th.sorting_desc:after {
  content: '';
}
.preview .panel-table table.dataTable thead th.sorting:after {
  content: '';
}
.preview .panel-table table.dataTable thead th.sorting_asc {
  content: '';
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6);
}
.preview .panel-table table.dataTable thead th.sorting_desc {
  content: '';
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
}
.preview .panel-table .processing {
  z-index: 99;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  display: none;
  width: 100%;
}
.preview .panel-table .processing .processing_content {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  -ms-transform: translateY(-52%);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.preview select.input-sm {
  height: 30px;
  line-height: 30px;
}
.preview .form-group-sm .form-control,
.preview .input-sm {
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 12px;
}
.preview .form-control {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.preview .form-control,
.preview output {
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  display: block;
}
.preview .table > caption + thead > tr:first-child > td,
.preview .table > caption + thead > tr:first-child > th,
.preview .table > colgroup + thead > tr:first-child > td,
.preview .table > colgroup + thead > tr:first-child > th,
.preview .table > thead:first-child > tr:first-child > td,
.preview .table > thead:first-child > tr:first-child > th {
  border-top: 0;
}
.preview .table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}
.preview .table > tbody > tr > td,
.preview .table > tbody > tr > th,
.preview .table > tfoot > tr > td,
.preview .table > tfoot > tr > th,
.preview .table > thead > tr > td,
.preview .table > thead > tr > th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.preview table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
}
.preview table.dataTable td,
.preview table.dataTable th {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.preview table.dataTable td.dataTables_empty,
.preview table.dataTable th.dataTables_empty {
  text-align: center;
}
.preview table.dataTable.nowrap th,
.preview table.dataTable.nowrap td {
  white-space: nowrap;
}
.preview div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}
.preview div.dataTables_wrapper div.dataTables_length select {
  width: 75px;
  display: inline-block;
}
.preview div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}
.preview div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}
.preview div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}
.preview div.dataTables_wrapper div.dataTables_info {
  padding-top: 8px;
  white-space: nowrap;
}
.preview div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}
.preview div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
}
.preview div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0;
}
.preview table.dataTable thead > tr > th.sorting_asc,
.preview table.dataTable thead > tr > th.sorting_desc,
.preview table.dataTable thead > tr > th.sorting,
.preview table.dataTable thead > tr > td.sorting_asc,
.preview table.dataTable thead > tr > td.sorting_desc,
.preview table.dataTable thead > tr > td.sorting {
  padding-right: 30px;
}
.preview table.dataTable thead > tr > th:active,
.preview table.dataTable thead > tr > td:active {
  outline: none;
}
.preview table.dataTable thead .sorting,
.preview table.dataTable thead .sorting_asc,
.preview table.dataTable thead .sorting_desc,
.preview table.dataTable thead .sorting_asc_disabled,
.preview table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}
.preview table.dataTable thead .sorting:after,
.preview table.dataTable thead .sorting_asc:after,
.preview table.dataTable thead .sorting_desc:after,
.preview table.dataTable thead .sorting_asc_disabled:after,
.preview table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: block;
  font-family: 'Glyphicons Halflings';
  opacity: 0.5;
}
.preview table.dataTable thead .sorting:after {
  opacity: 0.2;
  content: '\e150';
  /* sort */
}
.preview table.dataTable thead .sorting_asc:after {
  content: '\e155';
  /* sort-by-attributes */
}
.preview table.dataTable thead .sorting_desc:after {
  content: '\e156';
  /* sort-by-attributes-alt */
}
.preview table.dataTable thead .sorting_asc_disabled:after,
.preview table.dataTable thead .sorting_desc_disabled:after {
  color: #eee;
}
.preview div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important;
}
.preview div.dataTables_scrollBody > table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.preview div.dataTables_scrollBody > table > thead .sorting:after,
.preview div.dataTables_scrollBody > table > thead .sorting_asc:after,
.preview div.dataTables_scrollBody > table > thead .sorting_desc:after {
  display: none;
}
.preview div.dataTables_scrollBody > table > tbody > tr:first-child > th,
.preview div.dataTables_scrollBody > table > tbody > tr:first-child > td {
  border-top: none;
}
.preview div.dataTables_scrollFoot > .dataTables_scrollFootInner {
  box-sizing: content-box;
}
.preview div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
  margin-top: 0 !important;
  border-top: none;
}
@media screen and (max-width: 767px) {
  .preview div.dataTables_wrapper div.dataTables_length,
  .preview div.dataTables_wrapper div.dataTables_filter,
  .preview div.dataTables_wrapper div.dataTables_info,
  .preview div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
}
.preview table.dataTable.table-condensed > thead > tr > th {
  padding-right: 20px;
}
.preview table.dataTable.table-condensed .sorting:after,
.preview table.dataTable.table-condensed .sorting_asc:after,
.preview table.dataTable.table-condensed .sorting_desc:after {
  top: 6px;
  right: 6px;
}
.preview table.table-bordered.dataTable th,
.preview table.table-bordered.dataTable td {
  border-left-width: 0;
}
.preview table.table-bordered.dataTable th:last-child,
.preview table.table-bordered.dataTable th:last-child,
.preview table.table-bordered.dataTable td:last-child,
.preview table.table-bordered.dataTable td:last-child {
  border-right-width: 0;
}
.preview table.table-bordered.dataTable tbody th,
.preview table.table-bordered.dataTable tbody td {
  border-bottom-width: 0;
}
.preview div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}
.preview div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
}
.preview
  div.table-responsive
  > div.dataTables_wrapper
  > div.row
  > div[class^='col-']:first-child {
  padding-left: 0;
}
.preview
  div.table-responsive
  > div.dataTables_wrapper
  > div.row
  > div[class^='col-']:last-child {
  padding-right: 0;
}
.preview .dataTables_wrapper {
  position: relative;
  clear: both;
  *zoom: 1;
  zoom: 1;
}
.preview .dataTables_wrapper .dataTables_length {
  float: left;
}
.preview .dataTables_wrapper .dataTables_filter {
  float: right;
  text-align: right;
}
.preview .dataTables_wrapper .dataTables_filter input {
  margin-left: 0.5em;
}
.preview .dataTables_wrapper .dataTables_info {
  clear: both;
  float: left;
  padding-top: 0.755em;
}
.preview .dataTables_wrapper .dataTables_paginate .paginate_button.current,
.preview
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current:hover {
  color: #333 !important;
  border: 1px solid #979797;
  background-color: white;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, white),
    color-stop(100%, #dcdcdc)
  );
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, white 0%, #dcdcdc 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, white 0%, #dcdcdc 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, white 0%, #dcdcdc 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, white 0%, #dcdcdc 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, white 0%, #dcdcdc 100%);
  /* W3C */
}
.preview .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white !important;
  border: 1px solid #111;
  background-color: #585858;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #585858),
    color-stop(100%, #111)
  );
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, #585858 0%, #111 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, #585858 0%, #111 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, #585858 0%, #111 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, #585858 0%, #111 100%);
  /* W3C */
}
.preview .dataTables_wrapper .dataTables_paginate .paginate_button:active {
  outline: none;
  background-color: #2b2b2b;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #2b2b2b),
    color-stop(100%, #0c0c0c)
  );
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
  /* W3C */
  box-shadow: inset 0 0 3px #111;
}
.preview .dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em;
}
.preview .dataTables_wrapper .dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  font-size: 1.2em;
  background-color: white;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(25%, rgba(255, 255, 255, 0.9)),
    color-stop(75%, rgba(255, 255, 255, 0.9)),
    color-stop(100%, rgba(255, 255, 255, 0))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 25%,
    rgba(255, 255, 255, 0.9) 75%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 25%,
    rgba(255, 255, 255, 0.9) 75%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 25%,
    rgba(255, 255, 255, 0.9) 75%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 25%,
    rgba(255, 255, 255, 0.9) 75%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 25%,
    rgba(255, 255, 255, 0.9) 75%,
    rgba(255, 255, 255, 0) 100%
  );
}
.preview .dataTables_wrapper .dataTables_scroll {
  clear: both;
}
.preview .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
  *margin-top: -1px;
  -webkit-overflow-scrolling: touch;
}
.preview
  .dataTables_wrapper
  .dataTables_scroll
  div.dataTables_scrollBody
  > table
  > thead
  > tr
  > th,
.preview
  .dataTables_wrapper
  .dataTables_scroll
  div.dataTables_scrollBody
  > table
  > thead
  > tr
  > td,
.preview
  .dataTables_wrapper
  .dataTables_scroll
  div.dataTables_scrollBody
  > table
  > tbody
  > tr
  > th,
.preview
  .dataTables_wrapper
  .dataTables_scroll
  div.dataTables_scrollBody
  > table
  > tbody
  > tr
  > td {
  vertical-align: middle;
}
.preview
  .dataTables_wrapper
  .dataTables_scroll
  div.dataTables_scrollBody
  > table
  > thead
  > tr
  > th
  > div.dataTables_sizing,
.preview
  .dataTables_wrapper
  .dataTables_scroll
  div.dataTables_scrollBody
  > table
  > thead
  > tr
  > td
  > div.dataTables_sizing,
.preview
  .dataTables_wrapper
  .dataTables_scroll
  div.dataTables_scrollBody
  > table
  > tbody
  > tr
  > th
  > div.dataTables_sizing,
.preview
  .dataTables_wrapper
  .dataTables_scroll
  div.dataTables_scrollBody
  > table
  > tbody
  > tr
  > td
  > div.dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}
.preview .dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid #111;
}
.preview
  .dataTables_wrapper.no-footer
  div.dataTables_scrollHead
  table.dataTable,
.preview .dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
  border-bottom: none;
}
.preview .dataTables_wrapper:after {
  visibility: hidden;
  display: block;
  content: '';
  clear: both;
  height: 0;
}
@media screen and (max-width: 767px) {
  .preview .dataTables_wrapper .dataTables_info,
  .preview .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center;
  }
  .preview .dataTables_wrapper .dataTables_paginate {
    margin-top: 0.5em;
  }
}
@media screen and (max-width: 640px) {
  .preview .dataTables_wrapper .dataTables_length,
  .preview .dataTables_wrapper .dataTables_filter {
    float: none;
    text-align: center;
  }
  .preview .dataTables_wrapper .dataTables_filter {
    margin-top: 0.5em;
  }
}

/* End UifDataTable */
`;

class Preview extends Component {
  constructor() {
    super();

    this.state = { error: null };

    this.previewListeners = new PreviewListeners();
    this.previewHelpers = new PreviewHelpers();
    HandlebarsIntl.registerWith(Handlebars);
  }

  componentDidMount() {
    console.log('Initializing listeners!');
    this.previewListeners.initialize();
    console.log('Register listeners!');
    this.previewListeners.addEventListener('tab', v => {
      if (this.props.changeTab) this.props.changeTab(parseInt(v, 10));
      else
        this.props.snackBarStore.setMessage(
          '¡Funcionalidad solo válida durante la ejecución!'
        );
    });

    this.previewListeners.addEventListener('variable', v => {
      console.log('Variable', v);
      if (this.props.setVariable) this.props.setVariable(v.name, v.value);
    });
    
    this.defineCustomComponents();

    try {
      const script = document.getElementById('hbscript');
      if (script !== null) eval(script.innerHTML);
    } catch (error) {
      console.log(error);
      this.setError(error);
    }
  }

  componentDidUpdate() {
    try {
      const script = document.getElementById('hbscript');
      if (script !== null) eval(script.innerHTML);
    } catch (error) {
      console.log(error);
      this.setError(error);
    }
  }

  componentWillUnmount() {
    console.log('Closing listeners!');
    this.previewListeners.close();
  }

  setError(error) {
    this.setState({ error });
  }

  defineCustomComponents() {
    console.log('Registering CC');
    const { customComponents } = this.props;

    customComponents.forEach(c => {
      if (!customElements.get(c.tag)) {
        const CustomComponent = reactToWebComponent(c.component, React, ReactDOM);
        customElements.define(c.tag, CustomComponent);
      }
    });
  }

  render() {
    const {
      source,
      script,
      globalData,
      localData,
      classes,
      globalScope,
      theme,
      customComponents
    } = this.props;
    let templateContent = null;
    let globalScript = '';
    const { error } = this.state;
    globalScope.forEach(scope => {
      globalScript += scope.script.global ? `${scope.script.global}\n` : '';
    });

    const sourceWithScript = source.concat(
      '\n',
      `<scr{{!}}ipt id="hbscript">((global, open, getShared, setContent, setVariable, formatDate) => {
        //const $ = require('jquery');
        //import { $, jQuery } from 'jquery';

        const $global = global;
        const $helpers = {
          open,
          getShared,
          setContent,
          setVariable,
          formatDate
        };

        ${globalScript}

        return function (results) {
          const $results = results;
          
          ${script.local}
        };

      })(${JSON.stringify(globalData)}, ${this.previewHelpers.open}, ${this.previewHelpers.getShared
      }, ${this.previewHelpers.setElementContent}, ${this.previewHelpers.setVariable
      }, ${this.previewHelpers.formatDate})(${JSON.stringify(localData)})</scr{{!}}ipt>`
    );
    try {
      Handlebars.registerHelper('formatTime', function (date, format) {
        const mmnt = moment(date);
        return mmnt.isValid() ? mmnt.format(format) : '';
      });
      Handlebars.registerHelper('equals', function (v1, v2, options) {
        if (v1 === v2) {
          return options.fn(this);
        }
        return options.inverse(this);
      });
      Handlebars.registerHelper('formatCurrency', function (value, locale, currency) {
        const $currency = typeof currency == 'string' ? currency : 'USD';
        return new Intl.NumberFormat(locale, { style: 'currency', currency: $currency, currencyDisplay: 'narrowSymbol', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
      });
      Handlebars.registerHelper("x", function (expression, options) {
        var result;
        var context = this;
        result = (function () {
          try {
            return eval(expression);
          } catch (e) {
            console.warn('•Expression: {{x \'' + expression + '\'}}\n•JS-Error: ', e, '\n•Context: ', context);
          }
        }).call(context);
        return result;
      });

      Handlebars.registerHelper("compare", function (expression, options) {
        return Handlebars.helpers["x"].apply(this, [expression, options]) ? options.fn(this) : options.inverse(this);
      });
      Handlebars.registerHelper('contains', function (value, comparator, options) {
        return comparator.toLowerCase().includes(value) ? options.fn(this) : options.inverse(this);
      });

      Handlebars.registerHelper('object', function (value) {
        return JSON.stringify(value);
      });

      const template = Handlebars.compile(sourceWithScript);
      templateContent = (
        <PreviewStyles>
          <Typography component="span">
            <div
              className="preview"
              dangerouslySetInnerHTML={{ __html: template({ ...globalData, theme }) }}
            />
          </Typography>
        </PreviewStyles>
      );
    } catch (err) {
      console.log(err);
      templateContent = (
        <div style={{ textAlign: 'center' }}>
          <Chip
            classes={{ root: classes.root, label: classes.label }}
            avatar={
              <Avatar size={32} style={{ backgroundColor: orange.A700 }}>
                <ErrorIcon style={{ color: 'white' }} />
              </Avatar>
            }
            label="Ocurrió un error al analizar el template"
            style={{ margin: 10 }}
          />
        </div>
      );
    }
    return (
      <div>
        {error ? (
          <div style={{ textAlign: 'center' }}>
            <Chip
              classes={{ root: classes.root, label: classes.label }}
              avatar={
                <Avatar size={32} style={{ backgroundColor: orange.A700 }}>
                  <ErrorIcon style={{ color: 'white' }} />
                </Avatar>
              }
              label={error.message}
              style={{ margin: 10 }}
            />
          </div>
        ) : (
          templateContent
        )}
      </div>
    );
  }
}

export default withStyles(styleMaterial)(Preview);
