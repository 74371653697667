/*eslint-disable*/
import React, { Component } from 'react';
import { toJS } from 'mobx';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import KeyboardIconRight from '@material-ui/icons/KeyboardArrowRight';
import DetailsNavigatorStore from './stores/DetailsNavigatorStore';
import Preview from '../preview/Preview';
import Loader from '../loader';

const styles = {
  container: {
    margin: '1rem 0'
  },
  detailsContainer: {
    display: 'flex',
    marginBottom: '5rem'
  },
  innerContainer: {
    padding: '1rem',
    backgroundColor: '#fff',
    zIndex: '1',
    width: '100%'
  },
  sideList: {
    minWidth: '190px',
    backgroundColor: '#e0e0e0',
    padding: '0'
  },
  sideListItem: {
    borderBottom: '1px solid #fff',
    color: '#666666',
    fontSize: '14px',
    textAlign: 'right',
    textTransform: 'uppercase'
  },
  innerSideListItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  backButton: {
    backgroundColor: 'rgba(153, 153, 153, 0.2)',
    padding: '7px 8px',
    color: 'black'
  }
};

const ButtonsBar = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 1.5rem;
  width: 75%;

  & button {
    background-color: #3e73dc;
  }

  & button:not(:last-child) {
    margin-right: 1rem;
  }

  @media (max-width: 1076px) {
    /*justify-content: center;*/
    margin-right: 0;
    /*
    & button:not(:last-child) {
      margin-right: 0;
    }*/
  }

  @media (max-width: 724px) {
    width: 100%;
    justify-content: center;
  }
`;

const AnimatedIcon = styled.div`
  transform: translateX(50px);
  transition: all 0.5s linear;
  height: 24px;

  &.move {
    transform: translateX(0px);
  }
`;

const ContainerTabs = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
`;

const styleMaterial = theme => {
  return {
    buttonTab: {
      maxWidth: 'calc(33% - 2rem)',
      width: '200px',
      minHeight: '0px',
      fontSize: '14px !important',
      height: '40px'
      // '@media (min-device-width: 360px) and (max-device-width: 900px)': {
      //   fontSize: '18px !important',
      //   width: 'unset  !important',
      // }
    },
    button: {
      width: '200px',
      backgroundColor: 'rgb(255, 255, 255)',
      borderRadius: '5px',
      color: '#3f51b5',
      fontSize: '14px !important',
      height: '40px',
      '&:hover': {
        color: '#fff'
      }
    },
    extendedIcon: {
      marginRight: theme.spacing.unit,
      fontSize: 20
    },
  };
};

const isNumeric = type => {
  return (
    type === 'int' ||
    type === 'int16' ||
    type === 'int32' ||
    type === 'int64' ||
    type === 'byte' ||
    type === 'number' ||
    type === 'decimal'
  );
};

@observer
class DetailsNavigator extends Component {
  constructor(props) {
    super(props);
    this.detailsNavigatorStore = new DetailsNavigatorStore();
    if (props.tabIndex !== null) {
      this.detailsNavigatorStore.changeArea(props.tabIndex);
    }
    this.state = {
      left: '-250px',
      open: false
    };
  }

  componentDidMount = () => {
    const { items, qSiseStore, variables } = this.props;

    const requestData = this.detailsNavigatorStore
      .getActiveInnerNavigator(items)
      .data.map(d => {
        d.criteriaFilter.map(filter => {
          const f = filter;
          if (f.variableName !== null && f.variableName !== undefined) {
            f.value = variables.find(v => {
              return v.value === f.variableName;
            }).defaultValue;
            f.value = isNumeric(f.type) ? parseInt(f.value, 10) : f.value;
          }
          return f;
        });

        d.parameters.map(parameter => {
          const p = parameter;
          if (p.variableName !== null && p.variableName !== undefined) {
            p.value = variables.find(v => {
              return v.value === p.variableName;
            }).defaultValue;
            p.value = isNumeric(p.type) ? parseInt(p.value, 10) : p.value;
          }

          return p;
        });

        return d;
      });
    qSiseStore.executeQueries(requestData).then(response => {
      this.detailsNavigatorStore.initializeDatasets(items, response);
    });
  };

  setVariable = (name, value) => {
    this.props.setVariable(name, value);
  };

  changeOuterNavigationIndex = index => {
    if (this.detailsNavigatorStore.datasets[index].results[0] !== null) {
      this.detailsNavigatorStore.changeArea(index);
    } else {
      const { items, qSiseStore, variables } = this.props;
      this.detailsNavigatorStore.changeArea(index);
      const requestData = this.detailsNavigatorStore
        .getActiveInnerNavigator(items)
        .data.map(d => {
          d.criteriaFilter.map(filter => {
            const f = filter;
            if (f.variableName !== null && f.variableName !== undefined) {
              f.value = variables.find(v => {
                return v.value === f.variableName;
              }).defaultValue;
              f.value = isNumeric(f.type) ? parseInt(f.value, 10) : f.value;
            }
            return f;
          });

          d.parameters.map(parameter => {
            const p = parameter;
            if (p.variableName !== null && p.variableName !== undefined) {
              p.value = variables.find(v => {
                return v.value === p.variableName;
              }).defaultValue;
              p.value = isNumeric(p.type) ? parseInt(p.value, 10) : p.value;
            }

            return p;
          });

          return d;
        });
      qSiseStore.executeQueries(requestData).then(response => {
        this.detailsNavigatorStore.setDataSet(response);
      });
    }
  };

  changeInnerNavigationIndex = index => {
    const { items, qSiseStore, variables } = this.props;
    if (
      this.detailsNavigatorStore.getDataSetByIndex(index) !== null &&
      !this.detailsNavigatorStore.getInnerNavigators(items, index).isDynamic
    ) {
      this.detailsNavigatorStore.innerActiveIndex = index;
    } else {
      this.detailsNavigatorStore.innerActiveIndex = index;
      const requestData = this.detailsNavigatorStore
        .getActiveInnerNavigator(items)
        .data.map(d => {
          d.criteriaFilter.map(filter => {
            const f = filter;
            if (f.variableName !== null && f.variableName !== undefined) {
              f.value = variables.find(v => {
                return v.value === f.variableName;
              }).defaultValue;
              f.value = isNumeric(f.type) ? parseInt(f.value, 10) : f.value;
            }
            return f;
          });

          d.parameters.map(parameter => {
            const p = parameter;
            if (p.variableName !== null && p.variableName !== undefined) {
              p.value = variables.find(v => {
                return v.value === p.variableName;
              }).defaultValue;
              p.value = isNumeric(p.type) ? parseInt(p.value, 10) : p.value;
            }

            return p;
          });

          return d;
        });

        qSiseStore.executeQueries(requestData).then(response => {
          this.detailsNavigatorStore.setDataSet(response);
        });
    }
  };

  goToList = () => {
    this.props.router.push(`/qsise/search/${this.props.qSiseId}`);
  };

  openSideBar = () => {
    let rightPos;
    if (!this.state.open) rightPos = '0px';
    else rightPos = '-250px';

    this.setState({ left: rightPos, open: !this.state.open });
  };

  render() {
    const { items, classes, qSiseStore, theme, externalData, customComponents } = this.props;
    let globalResults = externalData ? { ext: externalData } : null;
    this.detailsNavigatorStore.datasets.forEach(dataset => {
      dataset.results.forEach(result => {
        const r = toJS(result);
        globalResults = { ...globalResults, ...r };
      });
    });

    return (
      <div>
        {/* <ContainerTabs>
          <ButtonsBar>
            {items.map((item, i) => {
              let activeStyle = {};
              if (i === this.detailsNavigatorStore.outerActiveIndex)
                activeStyle = { backgroundColor: '#ff9900' };

              return (
                <Button
                  color="primary"
                  type="button"
                  variant="contained"
                  key={item.id}
                  className={classes.buttonTab}
                  style={activeStyle}
                  onClick={() => this.changeOuterNavigationIndex(i)}
                >
                  {item.title}
                </Button>
              );
            })}
          </ButtonsBar>
        </ContainerTabs> */}
          {/* <List style={styles.sideList}>
            {this.detailsNavigatorStore
              .getInnerNavigators(items)
              .map((innerItem, i) => {
                let activeStyle = {};
                if (i === this.detailsNavigatorStore.innerActiveIndex) {
                  activeStyle = {
                    backgroundColor: '#d8e3f8',
                    color: '#000',
                    fontWeight: '600'
                  };
                }
                return (
                  <ListItem
                    button
                    key={`${innerItem.title}${i}`}
                    style={{ ...styles.sideListItem, ...activeStyle }}
                    onClick={() => {
                      this.changeInnerNavigationIndex(i);
                    }}
                  >
                    <ListItemText
                      primary={innerItem.title}
                      disableTypography
                    />
                    <AnimatedIcon
                      className={
                        i === this.detailsNavigatorStore.innerActiveIndex
                          ? 'move'
                          : ''
                      }
                    >
                      <KeyboardIconRight />
                    </AnimatedIcon>
                  </ListItem>
                );
              })}
          </List> */}
          <div>
            {this.detailsNavigatorStore.datasets.length > 0 &&
              this.detailsNavigatorStore.getActiveDataSet() !== null && (
                !qSiseStore.fetching ? <div>
                  <Preview
                    source={
                      this.detailsNavigatorStore.getActiveInnerNavigator(items)
                        .template
                    }
                    script={
                      this.detailsNavigatorStore.getActiveInnerNavigator(items)
                        .script
                    }
                    globalScope={toJS(
                      this.detailsNavigatorStore.getInnerNavigators(items)
                    )}
                    globalData={toJS(globalResults)}
                    localData={toJS(
                      this.detailsNavigatorStore.getActiveDataSet()
                    )}
                    changeTab={this.changeInnerNavigationIndex}
                    setVariable={this.setVariable}
                    snackBarStore={this.props.snackBarStore}
                    theme={theme}
                    customComponents={customComponents}
                  />
                </div> : <Loader theme={theme} />
              )}
          </div>
      </div>
    ); //this.props.items.length > 0 ? React.createElement(Container, null, null) : null;
  }
}

export default withStyles(styleMaterial)(DetailsNavigator);
